import {useEffect, useState} from "react";
import axios from "axios";

function RandomPagePopup() {
    const [loading, setLoading] = useState(true)

    function getAffSubParameters() {
        const params = new URLSearchParams(window.location.search);
        const affSubParams = {};

        for (const [key, value] of params) {
            if (key.includes('aff_sub')) {
                affSubParams[key] = value;
            }
        }

        return affSubParams;
    }

    function convertObjectToQueryString(obj) {
        const queryString = Object.keys(obj).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`).join('&');
        return queryString;
    }

    const getSingleOffer = () => {
        axios.get('https://loanbot-api.ldtrk.ru/api/v1/get_offers_lg?tkn=ii88xxxxxxxxxxxxxxxxxxx').then(res => {
            const banned = ['6296', '1658'];
            const approvedOffers = res.data.data.filter(offer => offer.affiliate_status === "approved");
            console.log(approvedOffers)
            const sortedData = approvedOffers.sort((a, b) => {
    // Проверяем, существуют ли необходимые данные
    const epcA = a.metrics_detailed && a.metrics_detailed["7days"] ? a.metrics_detailed["7days"].epc : 0;
    const epcB = b.metrics_detailed && b.metrics_detailed["7days"] ? b.metrics_detailed["7days"].epc : 0;

    return epcB - epcA;
});
            const topFive = sortedData.slice(0, 10);

            let randomSelection;
            do {
                const randomIndex = Math.floor(Math.random() * topFive.length);
                randomSelection = topFive[randomIndex];
            } while (banned.includes(randomSelection.legacy_id.toString()));

            const URLParams = convertObjectToQueryString(getAffSubParameters())
            const URL = `https://go.leadgid.ru/aff_c?aff_id=35153&offer_id=${randomSelection.legacy_id}&${URLParams}`
            window.location.replace(URL)
            // console.log('URL', URL, randomSelection)
        })
    }

    useEffect(() => {
        getSingleOffer()
    }, []);

    return (
        <div>
            {loading &&
                <div style={{
                    width: '100vw',
                    height: '100vh',
                    display: "flex",
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img src={'./pl.gif'}/>
                </div>
            }
        </div>
    );
}

export default RandomPagePopup;

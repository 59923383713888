import { useEffect, useState } from "react";

function RandomPage() {
    const offers = [
        {"2973": "web_zaim"},
        {"6173": "moi_zaem"},
        {"6149": "kopeechka"},
        {"2577": "turbozaim"},
        {"2661": "zaymigo"},
        {"4821": "srochnodengi"},
        {"6093": "adengi"}
    ];
    const [offersList, setOffersList] = useState(offers);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        const shuffledOffers = [...offersList].sort(() => 0.5 - Math.random());
        let foundOffer = null;
        for (let offer of shuffledOffers) {
            const offerKey = Object.keys(offer)[0];
            if (!localStorage.getItem(offerKey)) {
                foundOffer = offer;
                console.log('foundOffer', Object.keys(offer)[0])
                let params = new URLSearchParams(window.location.search);
                let url = `https://go.leadgid.ru/aff_c?aff_id=35153&offer_id=${Object.keys(offer)[0]}&aff_sub=${params.get('aff_sub')}&aff_sub2=${params.get('aff_sub2')}&aff_sub3=${params.get('aff_sub3')}&aff_sub4=${Object.values(offer)[0]}&aff_sub5=${params.get('aff_sub5')}`
                window.location.replace(url)
                localStorage.setItem(offerKey, offer[offerKey]);
                setSelectedOffer(offer);
                break;
            }
        }

        if (!foundOffer) {
            window.location.replace('https://www.nsvz.ru/ofr?utm_source=done_offers')
            setError('All offers are already in localStorage.');
        }
    }, []);

    return (
        <div>

        </div>
    );
}

export default RandomPage;
